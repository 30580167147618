<template>
  <div class="container">
    <van-popup
      v-model:show="nameAuthShow"
      position="bottom"
      @click-overlay="closePopup"
      @close="closePopup"
      closeable
      :overlay="false"
      :overlay-style="{ opacity: 1 }"
      color="rgba(33, 30, 32, 1)"
    >
      <div class="vpopup">
        <div class="popup">
          <div class="title">隐藏玩法</div>
        </div>
       <div class="item">
          <img src="@/assets/check.png" />
          <span>合成玩法</span>
        </div>
        <span class="text">{{headData.otherPlay}}</span>
        <div class="item top">
          <img src="@/assets/check.png" />
          <span>特殊权益</span>
        </div>
        <span class="text">{{headData.rights}}</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    headData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const nameAuthShow = ref(true);

    return {
      nameAuthShow,
    };
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.container {
  z-index: 999999;
  display: flex;  
  justify-content: center;
  position: relative;
  .vpopup {
    width: 100vw;
    height: 518px;
    z-index: 9999;
    background: rgba(33, 30, 32, 1);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .popup {
      height: 54px;
      width: 100vw;
      background: rgba(33, 30, 32, 1);
      display: flex;
      justify-content: center;
      .title {
        margin-top: 8px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
      }
    }
    .item {
      display: flex;
      justify-content: start;
      align-items: center;
      img {
        top: 54px;
        left: 26px;
        width: 18px;
        height: 18px;
        margin-left: 26px;
      }
      span {
        width: 148px;
        // height: 18px;
        font-size: 14px;
        line-height: 18px;
        font-weight: bolder;
        background: linear-gradient(
          90deg,
          rgb(242, 168, 78) 0%,
          rgba(217, 173, 115, 0.6) 100%
        );
        -webkit-background-clip: text;
        color: transparent;
      }
    }
    .text {
      // height: 54px;
      color: rgba(131, 131, 131, 1);
      font-size: 12px;
      margin-top: 16px;
      align-self: center;
      width: 324px;
      letter-spacing: 1px;
      line-height: 18px;
    }
  }
}
/deep/ .van-popup {
  background: rgba(33, 30, 32, 1);
}
.top {
  margin-top: 16px;
}
</style>
