<template>

  <div class="storeDetails">
    
        <Header title="" />
      <!-- <van-popup
        v-model:show="show"
        position="center"
        round="true"
        color="rgba(33, 30, 32, 1)"
      > -->
      <DoTaskPage :item="headData" :showPopTask="showPopTask" @close="showPopupTask" />
      <SharePoster  :nickName="nickName" v-if="showShareFlag" :phone="mobilex" :shareAddr="shareAddr" @close="closeSharePop"/>
      <combin v-if="showCombinFlag" :headData="headData" @close="showCombinFlag = false"></combin>
      <!-- </van-popup> -->

      <!-- 视频、图片、3d组件 -->
      <media :headData="headData"></media>
      <van-dialog v-model:show="showDialog"   class="showDialog"  theme= 'round-button'> 
        <!-- <img src="https://fastly.jsdelivr.net/npm/@vant/assets/apple-3.jpeg" /> -->
   
          <div class="t0" >
              <van-icon name="checked" class="success-icon" />
            <div class="t0-d" >领取成功</div>
         
        </div>
          <div class="t1">恭喜您获得盲盒</div>
           <div class="t2" >   
            <div class="t2-1-d">{{receiveBlindBoxData.allTotal}}</div>
           <div class="t2-2-d">个</div>
        </div>
           <div class="t3">门票赠送 x {{receiveBlindBoxData.payTotal}}</div>
           <div class="t4"> 创世赠送 x {{receiveBlindBoxData.empowermentTotal}}（  {{receiveBlindBoxData.empowermentNumber}} x {{receiveBlindBoxData.multiple}} ）</div>
       </van-dialog>

      <div class="content">
        <StoreInfoItem :item="headData" v-show="!is3d" />
        <StoreInfoItem2 :item="headData" v-show="is3d" />
        <pay-num
          v-if="showPayFlag"
          @numChange="numChange"
          @close="closePayNum()"
          :item="headData"
        ></pay-num>

        <HdExplain :headData="headData"></HdExplain>
        <div class="introduction">
          <div class="h" v-html="headData.content"></div>
        </div>
      </div>

    <!-- <div class="item_bottom_replace"></div> -->
    <div class="item_bottom">
      <div class="bottom">
        <div class="bottom_left">
          <div class="bottom_mark">¥</div>
          <div class="bottom_price">{{ headData.price }}</div>
        </div>

        <div :class="getSellOutStyle(headData.saleTime, headData.balanceTotal)">
          <div class="bottom_buy" @click="showPopupTask(true)" v-show="!toPlay">
            <div v-show="headData.payNameExplain == null">
                   {{ headData.payName }}
            </div>
            <div class="bottom_buy_pay" v-show="headData.payNameExplain">
            <div class="bottom_buy_payName">
            {{ headData.payName }}
            </div>
             <div class="bottom_buy_explain">
               {{ headData.payNameExplain}}
            </div>
            </div>
          </div>
          <div
            class="bottom_buy"
            v-if="getPayStatus(headData.saleTime, headData.balanceTotal) == 'selling'"
            @click="toShowPayNum"
            v-show="toPlay"
          >
            立即购买
          </div>
          <div
            class="pre_buy"
            v-if="getPayStatus(headData.saleTime, headData.balanceTotal) == 'countdown'"
            v-show="toPlay"
          >
            <span>即将开售</span>
            <span style="color: rgba(67, 233, 123, 1);">{{ hours + ":" + mins + ":" + second }}</span>
          </div>
          <div
            class="bottom_buy"
            v-else-if="
              getPayStatus(headData.saleTime, headData.balanceTotal) == 'sell_out'
            "
            v-show="toPlay"
          >
            已售完
          </div>
          <div
            class="pre_buy"
            v-else-if="getPayStatus(headData.saleTime, headData.balanceTotal) == 'pre'"
            v-show="toPlay"
          >
            <span>{{ monthAndDay(headData.saleTime) }}</span>
            <span>{{ hourAndmin(headData.saleTime) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch, onUnmounted } from "vue";
import Header from "../../components/Header2.vue";

import StoreInfoItem from "./components/StoreInfoItem.vue";
import StoreInfoItem2 from "./components/StoreInfoItem2.vue";
import StoreInfoSeriesItem from "./components/StoreInfoSeriesItem.vue";
import DoTaskPage from "./components/DoTaskPage.vue";
import TaskRull from "./components/TaskRull.vue";
import axios from "../../request/request";
import { useRouter, useRoute } from "vue-router";
import { Toast,Dialog } from "vant";
import { ref } from "vue";
import PayNum from "@/pages/myStore/components/pay-num";
import HdExplain from './components/HdExplain'
import Media from "@/components/Media";
import combin from "@/pages/myStore/components/combin";
import SharePoster from "@/pages/myStore/components/SharePoster"
export default {
  props: ["item"],
  components: {
	  Media,
    Header,
    HdExplain,
    StoreInfoItem,
    StoreInfoItem2,
    StoreInfoSeriesItem,
    DoTaskPage,
    TaskRull,
    PayNum,
    combin,
    SharePoster
  },
  inject: ['toApplyHandle', 'reload'],
  setup() {
    const route = useRoute();

    const show = ref(false);
    const hours = ref("00");
    const mins = ref("00");
    const second = ref("00");
    let countDownTimer = ref({});
    const showPopup = () => {
      show.value = true;
    };
    let showPopTask = ref(-1);
    let showShareFlag = ref(false);
    let mobilex = ref('')
    let nickName = ref('')
    let sp = ref('')
    let shareAddr = ref('')
    const showDialog = ref(false);
    let data = reactive({
      mid: "",
      contentData: {},
      headData: {},
      receiveBlindBoxData: {},
        userId:"0",
      // isImg: false,
      // isVideo: false,
      // is3d: false,
      // isRaffle: false,
      // isOtherPlay: false,
      // toPlay: false,
      centent_nav_list: {
        pic: "https://mj-1304255817.cos.ap-guangzhou.myqcloud.com/shjc/webapp/gxt.png",
        title: "嵩山少林-观星台",
        sales: "10000",
        price: "39.90",
        source: "全球限量首发",
        localSource: "河南嵩山少林寺景区·独家授权",
        tabChildShow: true,
        h:
          '<img src="https://mj-1304255817.cos.ap-guangzhou.myqcloud.com/shjc/webapp/20220413031444_840.jpeg" alt="undefined">',
      },
    });
    console.log("----" + route.query.mid);
    //数据请求
    var THIS = this;
    const getIntervalHour = (date1, date2) => {
      if (!date2) {
        return 0;
      }
      date1 = new Date(date1.replace(/-/g, "/"));
      date2 = new Date(date2.replace(/-/g, "/"));
      var diff = date1 - date2;
      if (isNaN(diff)) {
        console.log("无效的时间值");
        return;
      }
      return diff / (1000 * 60 * 60);
    };

    const formatDate = (date) => {
      var seperator1 = "-";
      var seperator2 = ":";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        date.getHours() +
        seperator2 +
        date.getMinutes() +
        seperator2 +
        date.getSeconds();
      return currentdate;
    };
    const monthAndDay = (date) => {
      date = new Date(date.replace(/-/g, "/"));
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      return month + "月" + strDate + "日";
    };
    const hourAndmin = (date) => {
      date = new Date(date.replace(/-/g, "/"));
      let hours = date.getHours();
      let mins = date.getMinutes();
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      if (mins >= 0 && mins <= 9) {
        mins = "0" + mins;
      }
      return hours + ":" + mins + "开售";
    };
    const getSellOutStyle = (saleTime, balanceTotal) => {
      if (getPayStatus(saleTime, balanceTotal) == "sell_out") {
        return "bottom_right_sell_out";
      } else {
        return "bottom_right";
      }
    };
    const getPayStatus = (saleTime, balanceTotal) => {
      if (balanceTotal == "0") {
        return "sell_out";
      }
      if (getIntervalHour(formatDate(new Date()), saleTime) >= 0) {
        return "selling";
      }
      if (
        getIntervalHour(formatDate(new Date()), saleTime) < 0 &&
        Math.abs(getIntervalHour(formatDate(new Date()), saleTime)) <= 24
      ) {
        return "countdown";
      }
      if (
        getIntervalHour(formatDate(new Date()), saleTime) < 0 &&
        Math.abs(getIntervalHour(formatDate(new Date()), saleTime)) > 24
      ) {
        return "pre";
      }
    };
    const countTime = () => {
      if (!data.headData.saleTime) {
        return;
      }
      if (countDownTimer.value) {
        clearTimeout(countDownTimer);
      }
      var now = new Date().getTime();
      var end = new Date(data.headData.saleTime.replace(/-/g, "/")).getTime();
      var leftTime = end - now;
      var h, m, s;
      if (leftTime >= 0) {
        h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        m = Math.floor((leftTime / 1000 / 60) % 60);
        s = Math.floor((leftTime / 1000) % 60);
      }
      if (h >= 0 && h <= 9) {
        h = "0" + h;
      }
      if (m >= 0 && m <= 9) {
        m = "0" + m;
      }
      if (s >= 0 && s <= 9) {
        s = "0" + s;
      }
      hours.value = h;
      mins.value = m;
      second.value = s;
      countDownTimer = setTimeout(countTime, 1000);
    };
    onMounted(() => {
      // getData();
    });
    onUnmounted(() => {
      if (countDownTimer.value) {
        clearTimeout(countDownTimer);
      }
    });

    return {
      ...toRefs(data),
      show,
      showPopup,
      getIntervalHour,
      showPopTask,
      formatDate,
      getPayStatus,
      getSellOutStyle,
      monthAndDay,
      hourAndmin,
      hours,
      mins,
      second,
      countTime,
      countDownTimer,
      route,
      mobilex,
      nickName,
      sp,
      showShareFlag,
      shareAddr,
      showDialog
    };
  },
  data() {
    console.log("--isVideo--");
    console.log(this.isVideo);
    // this.getVideos();
    return {
      // headData: {},
      showAuthFlag: false,
      isImg: false,
      flush: true,
      isVideo: false,
      is3d: false,
      showPayFlag: false,
      isRaffle: false,
      isOtherPlay: false,
      toPlay: false,
      showCombinFlag: false,
    };
  },
  mounted() {
    (this.isImg = false),
      (this.isVideo = false),
      (this.is3d = false),
      (this.isRaffle = false),
      (this.isOtherPlay = false),
      (this.toPlay = false),
      this.$nextTick(() => {
        this.getData();
      });

       if (this.route.query.orderNo !== null) {
        //queryOrderStatus(route.query.orderNo);
        if (this.route.query.orderNo !== undefined) {
         
           Dialog.alert({
                      title: "支付已结束",
                      message: "请在我的页面查看结果",
                    }).then(() => {
                      // on close
                    });
        }
        
       }

  },
  methods: {
    numChange(nums) {
      this.showPayFlag = false;
      this.toApply(nums);
    },
    toShowPayNum() {
      this.showPayFlag = true;
    },
    showPayNum() {
      this.showPayFlag = true;
    },

    closePayNum() {
      this.showPayFlag = false;
    },

 
    getData() {
      var THIS = this;
      // axios
      //   .get("app/content/" + THIS.route.query.mid)
    const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));

        if(!userOpenid){
          this.userId = "0";
        }else if (userOpenid.id === null) {
         this.userId = "0";
      }else{
        this.userId = userOpenid.id;
      }

       axios
        .post("app/content" , {
           cid: THIS.route.query.mid,
           userId: THIS.userId,
        })
        .then(function (res) {
          console.log(res);
          THIS.is3d = false;
          if (res.status === 200 && res.data.code === 0) {
            THIS.headData = res.data.headData;
            if (THIS.headData.showType == "3d") {
              THIS.is3d = true;

              //setTimeout(THIS.$refs.tdPage.flush(),2000)
              let flush = window.sessionStorage.getItem("flush");
              if (flush == "true") {
                THIS.$nextTick(() => {
                  window.sessionStorage.setItem("flush", false);
                  location.reload();
                });
              }

              console.log("--is3d--" + THIS.is3d);
            }
            if (THIS.headData.payFlag == "0") {
              THIS.toPlay = true;
              if (
                THIS.getPayStatus(THIS.headData.saleTime, THIS.headData.balanceTotal) ==
                "countdown"
              ) {
                setTimeout(THIS.countTime, 1000);
              }
              console.log("--toPlay--" + THIS.toPlay);
            }
            console.log(THIS.headData);
          }
          console.log(THIS.headData);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showPopupTask(subStatus) {
      if (this.headData.payFlag === '2') {
        this.showCombinFlag = true
        return false
      } else if (this.headData.payFlag === '3'){
        this.$router.push({path: './compose', query: {mid: this.route.query.mid}})
        return false
      }else if (this.headData.payFlag === '4'){ 
            Toast.loading({
            message: '加载中...',
            forbidClick: true,
            });
             this.receiveBlindBox()
             
            return false
      }else if (this.headData.payFlag === '5'){ 
         this.share() 
       //this. showDialog = true;
          return false
      }if (this.headData.payFlag === '6'){ 
        Toast(this.headData.payName)
          return false
      }
      if (subStatus) {
        let tmp = Math.floor(Math.random() * 100);
        this.showPopTask = tmp;
      } else {
        this.showPopTask = -1;
      }
    },

 share() {
    const userOpenid = JSON.parse(
        window.sessionStorage.getItem("userOpenid")
      );
     if(userOpenid) {
       this.mobilex = userOpenid.mobilex
       this.nickName = userOpenid.nickname
     }
     var THIS =this
     axios.post("app/userShare", {
          id: userOpenid.id,
          contentId: THIS.route.query.mid,
        }).then(function (res) {
          if (res.status === 200 && res.data.retCode === 0) {
            
            if(res.data.data) {
              THIS.sp = res.data.data.sp
             // THIS.shareAddr = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx23454132f087d21b&redirect_uri=https%3A%2F%2Fybanj.com/sp='+res.data.data.sp+'&response_type=code&scope=snsapi_base&state=state#wechat_redirect'
             THIS.shareAddr = 'https://ybanj.com/?sp='+res.data.data.sp
             //  THIS.shareAddr = '123'
              THIS.showShareFlag = true
            }
            Toast.clear();
          } else {
            Toast.fail("获取分享信息失败" + res.data.retMsg);
          }
        })
        .catch(function (error) {
          Toast.fail("请检查网络");
        });
     console.log("去分享")
    },
  receiveBlindBox() {
    const userOpenid = JSON.parse(
        window.sessionStorage.getItem("userOpenid")
      );
     var THIS =this
     axios.post("app/receiveBlindBox", {
          id: userOpenid.id,
          cid: THIS.route.query.mid,
        }).then(function (res) {
          if (res.status === 200 && res.data.retCode === 0) {
             THIS.receiveBlindBoxData = res.data.data;
             THIS. showDialog = true;
             THIS.headData.payFlag = '5';
               THIS.getData();
            Toast.clear();
          } else {
            Toast.fail("领取失败" + res.data.retMsg);
          }
        })
        .catch(function (error) {
          Toast.fail("请检查网络");
        });
     console.log("领取盲盒")
    },


    
   closeSharePop() {
      this.showShareFlag = false
    },
    // 查询订单状态
    queryOrderStatus(orderNo) {
      var THIS = this;
      console.log("查询订单状态：" + orderNo);
      axios
        .post("app/queryOrderStatus", {
          orderNo: orderNo,
        })
        .then(function (res) {
          if (res.status === 200 && res.data.code === 0) {
            console.log("清除定时器");
            clearInterval(this.timer);
            THIS.$router.push("./mine");
          } else {
            Toast(res.data.message);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          Toast.fail("请检查网络");
          clearInterval(THIS.timer);
        });
    },

    toApply(nums) {
 const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
       if (userOpenid === null) {
        Toast("请去我的页面登录后再购买");
        this.$router.push({ path: "./login" });
        return;
      }
      if (userOpenid.mobile === null) {
        Toast("请去我的页面登录后再购买");
        this.$router.push({ path: "./login" });
        return;
      }


      this.toApplyHandle(nums, this.headData.id, null, () => {
        this.reload()
      })
      // eslint-disable-next-line eqeqeq
    },
   
  },

  // created: function () {
  //           console.log("--created-" );
  //             var order_no = this.getUrlParam("order1");
  //                 console.log("--order_no-" +order_no);
  //                  console.log("-------" + this.route.query.orderNo);
  //     //  if (route.query.orderNo !== null) {
  //     //    queryOrderStatus(route.query.orderNo);
  //     //  }
  //          // alert("载入"+order_no)
  //            if (order_no !== null) {
  //               this. queryOrderStatus(order_no);
  //            }
  // }
};
</script>

<style lang="less" scoped>
/deep/.van-dialog{
    background: rgba(33, 30, 32, 1);
    color: rgba(255, 255, 255, 1);
font-size: 18px;
width: 309px;
}
/deep/.van-button--default{
  background: rgba(33, 30, 32, 1);
}
/deep/.van-dialog__footer {
 //display:flex;
  position: relative;
left: 5;
// right: 5;
text-align: center;
  width: 149px;
height: 35px;
 
  left: 50%; 
    
    transform: translate(-50%, -10%);   
}
/deep/.van-dialog__confirm, .van-dialog__confirm:active {
    color: rgba(255, 255, 255, 1);
  width: 149px;
height: 35px;
background: linear-gradient(90deg, rgba(143, 105, 59, 1) 0%, rgba(217, 173, 115, 1) 100%);
border-radius: 17.5px;


  text-align: center;
   justify-content: center;
 align-items:center;
 align-content:center;
}
/deep/.van-action-bar {
background: #ffffff00
}
.vpopup {
  width: 350px;
  background: rgba(33, 30, 32, 1);

  .popup {
    background: rgba(33, 30, 32, 1);
    padding-bottom: 50px;

    .title {
      padding-top: 20px;

      text-align: center;
      color: rgba(217, 173, 115, 1);
      font-size: 16px;
    }
    .info {
      // text-align: center;
      color: rgba(131, 131, 131, 1);
      font-size: 14px;
      padding: 20px;
    }
    .input-item {
      .item {
        padding: 10px;
      }
    }

    .save {
      position: relative;
      margin-top: 40px;
      .info {
        width: 150px;
        height: 30px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        line-height: 30px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        border-radius: 20px;
        padding: 5px 20px 5px 20px;

        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
}

.storeDetails {
  height: 100%;
  background: rgba(33, 30, 32, 1);
  width: 100vw;
  overflow: auto;
  width: 100%;
  position: fixed;
  padding-top: 40px;
  box-sizing: border-box;
  // display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling:touch
  
}
.showDialog{
  width: 200px;
  height: 200px;
  .t0{
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
   margin-top: 10px;
     .t0-d{
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    margin-top: 10px;
     text-align:center;
     line-height: 24px;
     margin-left: 5px;
     }
     .success-icon{
     color: rgba(151, 211, 142, 1);
      font-size: 28px;
        text-align:center;
            margin-top: 10px;
     }
  }
  .t1{
   color: rgba(255, 255, 255, 1);
    font-size: 14px;
    margin-top: 10px;
     text-align:center;
  }
  .t2{
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 15px;
     .t2-1-d{
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
     text-align:center;
     line-height: 24px;
     }
    .t2-2-d{
    color: rgba(255, 255, 255, 1);
    font-size: 10px;
     margin-left: 5px;
     margin-top: 2px;
     line-height: 28px;

     }
  }


  .t3{
    color: rgba(131, 131, 131, 1);
    font-size: 14px;
    margin-top: 10px;
     text-align:center;
  }
  .t4{
    color: rgba(131, 131, 131, 1);
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
     text-align:center;
  }
}
::v-deep .v {
    border-radius: 0;
  }

.content {
  border-radius: 10px;
  display: flex;
  //   padding: 10px;
  flex-direction: column;
  // margin-top: 40px;
  // padding-bottom: 1px;
  
  .v {
    width: 100%;
    height: 360px;
    margin-top: 40px;
    border-radius: 10px 10px 0 0;
    // margin-right: 20px;
  }
  .showIcon {
    top: 274px;
    left: 305px;
    position: absolute;
    width: 53px;
    height: 46px;
  }

  img {
    width: 100%;
    // height: 400px;
    // border-radius: 10px 10px 0 0;
    margin-right: 20px;
  }
  .item_right {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: -50px 10px 0 10px;
    z-index: 20;
    background: rgba(50, 50, 50, 1);
    border-radius: 10px;
    margin-top: 100%;
    .title {
      color: white;
      font-weight: 500;
      font-size: 14px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .sales {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      .sales_info {
        color: rgba(113, 72, 12, 1);
        font-size: 12px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        border-radius: 20px;
        padding: 5px 20px 5px 20px;
        margin-top: 10px;
        margin-left: 10px;
        z-index: 10;
      }
      .sales_data {
        color: rgba(217, 173, 115, 1);
        font-size: 12px;
        background: rgba(33, 30, 32, 1);
        border-radius: 20px;
        padding: 5px 20px 5px 30px;
        margin-top: 10px;
        margin-left: -20px;
      }
      .sales_source {
        color: rgba(113, 72, 12, 1);
        font-size: 12px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        border-radius: 20px;
        padding: 5px 20px 5px 20px;
        margin-top: 10px;
        margin-left: 20px;
      }
    }
    .bottom_info {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin-bottom: 10px;
      .bottom_left {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-left: 10px;
        margin-top: 10px;
        .bottom_image {
          .image {
            height: 15px;
            width: 12px;
            margin-top: 10px;
          }
        }
        .bottom_local_source {
          font-size: 10px;
          color: rgba(217, 173, 115, 1);
          display: flex;
          align-items: center;
          margin-left: -15px;
        }
      }
      .bottom_right {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-top: 10px;
        margin-right: 20px;
        .bottom_mark {
          font-weight: 500;
          font-size: 20px;
          color: white;
          margin-top: 6px;
          margin-right: 6px;
        }
        .bottom_price {
          font-weight: 500;
          font-size: 28px;
          color: white;
        }
      }
    }
    .bottom_info1 {
      margin-bottom: 10px;
      .bottom_local_source {
        font-size: 10px;
        color: rgba(128, 128, 128, 1);
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }

  .introduction {
    display: flex;
    height: 100px;
    margin: 0px 10px 0 10px;
    //  padding-top: 20px;
    border-radius: 10px;
    // width:100%;
    // padding:10px;
    margin-bottom: 3rem;
    .h {
      // width: 100px;
      height: 101%;
      ::v-deep p {
        margin: 0;
      }
    }
    ::v-deep img {
      max-width: 355px;
      margin: 12px 0 80px 0;
      border-radius: 10px;
      display: block;
    }
    ::v-deep div {
      // background: rgba(50, 50, 50, 1);
    }
  }
  // .introduction >>> .img{
  // width:100%;
  // object-fit:fill;
  // }
  .f {
    flex: 1;
    overflow-y: auto;
    display: flex;
    width: 100%;
    .h {
      width: 100px;
    }
  }
}
.item-bottom_repace {
  height: 60px;
}
.item_bottom_replace {
  height: 60px;
}
.item_bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(33, 30, 32, 1);

  width: 100%;
  .bottom {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 15px;
    .bottom_left {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-top: 10px;
      margin-right: 20px;
      .bottom_mark {
        font-weight: 500;
        font-size: 20px;
        color: white;
        margin-top: 6px;
        margin-right: 6px;
      }
      .bottom_price {
        font-weight: 500;
        font-size: 28px;
        color: white;
      }
    }
    .bottom_right {
      // width: 99px;
      margin-left: 10px;
      margin-top: 10px;
      min-width: 99px;
      height: 35px;
      background: linear-gradient(
        90deg,
        rgba(143, 105, 59, 1) 0%,
        rgba(217, 173, 115, 1) 100%
      );
      border-radius: 17.5px;
      .bottom_buy {
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        // margin-left: -15px;
        text-align: center;
        padding: 5px 20px 5px 20px;
        .bottom_buy_pay{
           line-height: 13px;
      .bottom_buy_payName{
           color: rgba(255, 255, 255, 1);
            font-size: 12px;
        }
       .bottom_buy_explain{
           color: rgba(255, 255, 255, 1);
            font-size: 10px;
        }
        }
      }
       
      .pre_buy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 99px;
        height: 35px;
        color: rgba(255, 255, 255, 1);
        letter-spacing: 1px;
        font-size: 12px;
        line-height: 14px;
      }
    }
    .bottom_right_sell_out {
      // width: 99px;
      margin-left: 10px;
      margin-top: 10px;
      background: rgba(130.99349999999998, 130.99349999999998, 130.99349999999998, 1);
      border-radius: 17.5px;
      .bottom_buy {
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        // margin-left: -15px;
        text-align: center;
        padding: 5px 20px 5px 20px;
      }
    }
  }
}
</style>
