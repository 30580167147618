<template>
  <div class="item">
    <div class="vpopup">
      <div class="popup">
        <div class="dt">
          <div class="dtitle">做任务 抽好礼</div>
          <div class="gz">
            <span>规则</span>
            <van-icon name="arrow" />
          </div>
        </div>

        <div class="item-goods" @click="toStore(item)">
          <img :src="headData.pic" alt="" />
          <div class="item_right">
            <div class="title">{{ headData.title }}</div>
            <div class="sales">活动结束后5个工作日内发放</div>
            <div class="label">
              <div class="cpd">
                <div class="jp">我的奖品</div>
              </div>
              <div class="cpd">
                <div class="cq">点击抽取</div>
                <div class="cs">剩余次数0</div>
              </div>
            </div>
          </div>
        </div>

        <div class="info">完成任务即可获得1次抽奖机会</div>
        <div class="input-item">
          <div class="item">
            <van-cell-group inset>
              <div class="top">
                <div class="top_info">
                  <div class="top_title">
                    <div class="title">点击了解{{ headData.name }}（1/0）</div>
                    <div class="code">每日浏览获得一次抽奖次数</div>
                  </div>
                </div>
                <div class="top_info_right">再看看</div>
              </div>
            </van-cell-group>
          </div>
          <div class="item">
            <van-cell-group inset>
              <div class="top">
                <div class="top_info">
                  <div class="top_title">
                    <div class="title">邀请好友一起抽（已邀请0位）</div>
                    <div class="code">
                      每邀请一个好友登陆认证，奖励一次抽奖次数
                    </div>
                  </div>
                </div>
                <div class="top_info_right">去完成</div>
              </div>
            </van-cell-group>
          </div>
          <div class="item">
            <van-cell-group inset>
              <div class="top">
                <div class="top_info">
                  <div class="top_title">
                    <div class="title">完成实名认证（0/1）</div>
                    <div class="code">在“我的”中完成实名认证</div>
                  </div>
                </div>
                <div class="top_info_right">去完成</div>
              </div>
            </van-cell-group>
          </div>
          <div class="item">
            <van-cell-group inset>
              <div class="top">
                <div class="top_info">
                  <div class="top_title">
                    <div class="title">每日签到获取抽签次数（1/1）</div>
                    <div class="code">每日签到获得1次抽签次数</div>
                  </div>
                </div>
                <div class="top_info_right">签到</div>
              </div>
            </van-cell-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Popup, Button } from "vant";
import { Toast } from "vant";
import axios from "../../../request/request";
export default {
  props: ["item"],
  setup() {
    let data = reactive({
      headData: {},
    });
    //数据请求
    const getData = async () => {
      axios
        .post("app/taskItemById", {
          id: "1",
        })
        .then(function (res) {
          console.log(res);
          console.log(res.status);
          console.log(res.data.code);
          if (res.status === 200 && res.data.code === 0) {
            data.headData = res.data.headData;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      getData();
    });

    return {
      ...toRefs(data),
    };
  },

  data() {
    return {
      tabChildShow: this.item.tabChildShow,
    };
  },
};
</script>

<style lang='less' scoped>
.item:not(:first-child) {
}
.item {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  z-index: 20;
  background: rgba(33, 30, 32, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  .vpopup {
    width: 380px;
    background: rgba(33, 30, 32, 1);

    .popup {
      background: rgba(33, 30, 32, 1);
      padding-bottom: 10px;
      .dt {
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .dtitle {
          padding-top: 10px;
          position: absolute;
          text-align: center;
          color: rgba(217, 173, 115, 1);
          font-size: 16px;
        }
        .gz {
          padding-top: 10px;

          text-align: center;
          color: rgba(166, 166, 166, 1);
          font-size: 12px;
          position: absolute;
          right: 20px;
        }
      }

      .item-goods {
        background: rgba(50, 50, 50, 1);
        border-radius: 10px;
        display: flex;
        padding: 10px;
        margin: 10px;
        img {
          width: 121px;
          height: 121px;
          border-radius: 10px;
          margin-right: 20px;
        }
        .item_right {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          .title {
            color: rgba(255, 255, 255, 1);
            font-size: 12px;
          }
          .sales {
            color: rgba(166, 166, 166, 1);
            font-size: 10px;
          }
          .label {
            display: flex;
            justify-content: space-between;
            div:not(:first-child) {
              margin-left: 5px;
            }
            .jp {
              color: rgba(217, 173, 115, 1);
              font-size: 14px;
              border: 1px solid #ffc40047;
              border-radius: 17.5px;
              background-color: #211e20;
              padding: 2px 10px;
            }
            .cpd {
              .cq {
                color: rgba(255, 255, 255, 1);
                font-size: 14px;
                background: linear-gradient(
                  90deg,
                  rgba(143, 105, 59, 1) 0%,
                  rgba(217, 173, 115, 1) 100%
                );
                border-radius: 17.5px;
                padding: 2px 10px;
              }
              .cs {
                color: rgba(166, 166, 166, 1);
                font-size: 8px;
                text-align: center;
              }
            }
          }
        }
      }

      .info {
        text-align: center;
        font-size: 16px;

        padding: 5px;
        color: rgba(217, 173, 115, 1);
      }
      .input-item {
        .item {
          padding: 10px;

          .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 58px;
            background: rgba(50, 50, 50, 1);

            .top_info {
              height: 58px;
              overflow: hidden; /*触发BFC，避免父子元素外边距合并*/
              .top_title {
                display: flex;
                flex-direction: column;
                //  margin-top:13px;

                .title {
                  padding-top: 10px;
                  color: rgba(255, 255, 255, 1);
                  font-size: 12px;
                  margin-left: 5px;
                  margin-left: 10px;
                }
                .code {
                  color: rgba(196, 196, 196, 1);
                  margin-top: 7px;
                  font-size: 8px;
                  margin-left: 10px;
                }
              }
            }
            .top_info_right {
              height: 28px;
              line-height: 26px;
              text-align: center;
              display: table-cell;
              vertical-align: middle;
              color: rgba(255, 255, 255, 1);
              font-size: 12px;
              // margin-left: 40px;
              width: 64px;

              background: linear-gradient(
                90deg,
                rgba(143, 105, 59, 1) 0%,
                rgba(217, 173, 115, 1) 100%
              );
              border-radius: 17.5px;
              margin-top: 15px;
              margin-right: 10px;
            }
          }
        }
      }

      .save {
        position: relative;
        margin-top: 40px;
        .info {
          width: 150px;
          height: 30px;
          text-align: center;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          line-height: 30px;
          background: linear-gradient(
            90deg,
            rgba(217, 173, 115, 1) 0%,
            rgba(235, 200, 155, 1) 100%
          );
          border-radius: 20px;
          padding: 5px 20px 5px 20px;

          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
</style>