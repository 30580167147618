<template>
  <div class="item" >
    <div class="title">{{ item.title }}</div>
    <div class="sales">
      <div class="sales_info">限量</div>
      <div class="sales_data">{{ item.sales }}份</div>
      <div class="sales_source">{{ item.source }}</div>
    </div>
    <div class="bottom_info">
      <div class="bottom_left">
        <img class="image" src="../../../assets/local_icon.png" />
        <span>{{ item.localSource }}</span>
        <!-- <div class="bottom_image">
          <img class="image" src="../../../assets/local_icon.png" />
        </div>
        <div class="bottom_local_source">{{ item.localSource }}</div> -->
      </div>
    </div>
    <div class="bottom_info1">
      <div class="bottom_local_source">{{ item.info }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  props: ["item"],
  setup() {
    let data = reactive({
      top: -10,
    });

    return {
      ...toRefs(data),
    };
  },

  data() {
    return {
      tabChildShow: this.item.tabChildShow,
    };
  },
};
</script>

<style lang='less' scoped>
.item:not(:first-child) {
}
.item {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: -40px 10px 0 10px;
  z-index: 20;
  // margin-top: 340px;
  background: rgba(50, 50, 50, 1);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  //  margin-top: 100%;
  // margin-top: var(--top);
  .title {
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .sales {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      .sales_info {
        color: rgba(113, 72, 12, 1);
        font-size: 12px;
        line-height: 13px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        // border-radius: 18px;
        padding: 2px 5px 2px 5px;
        margin-top: 10px;
        margin-left: 10px;
        z-index: 10;
      }
      .sales_data {
        color: rgba(217, 173, 115, 1);
        font-size: 12px;
        line-height: 13px;
        background: rgba(33, 30, 32, 1);
        // border-radius: 18px;
        padding: 2px 5px 2px 25px;
        margin-top: 10px;
        margin-left: -20px;
      }
      .sales_source {
        color: rgba(113, 72, 12, 1);
        font-size: 12px;
        line-height: 13px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        // border-radius: 18px;
        padding: 2px 8px 2px 8px;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  .bottom_info {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    // margin-bottom: 10px;
    .bottom_left {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-left: 10px;
      margin-top: 10px;
      img {
        height: 15px;
        width: 15px;
      }
      span {
        font-size: 10px;
        height: 15px;
        color: rgba(217, 173, 115, 1);
        display: flex;
        margin-left: 5px;
      }
      .bottom_image {
        .image {
          height: 15px;
          width: 12px;
          // margin-top: 10px;
        }
      }
      .bottom_local_source {
        font-size: 10px;
        color: rgba(217, 173, 115, 1);
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }
    .bottom_right {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-top: 10px;
      margin-right: 20px;
      .bottom_mark {
        font-weight: 500;
        font-size: 20px;
        color: white;
        margin-top: 6px;
        margin-right: 6px;
      }
      .bottom_price {
        font-weight: 500;
        font-size: 28px;
        color: white;
      }
    }
  }
  .bottom_info1 {
    // margin-bottom: 10px;
    .bottom_local_source {
      font-size: 10px;
      color: rgba(128, 128, 128, 1);
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-left: 10px;
    }
  }
}
</style>