<template>
  <div class="container">
    <van-popup
      v-model:show="nameAuthShow"
      position="bottom"
      @click-overlay="closePopup"
      @close="closePopup"
      round
      :overlay="false"
      :overlay-style="{ opacity: 1 }"
      color="rgba(33, 30, 32, 1)"
    >
      <div class="vpopup">
        <div class="price">{{'￥ '+ (item.price * nums).toFixed(2)}}</div>
        <div class="title">{{item.title}}</div>
        <div class="order_num" > 
          <span>购买数量</span>
          <van-stepper class="stepper" v-model="nums" min="1" :max="item.payMaxNum" />
        </div>
        <div class="next" @click="numChange"><span>下一步</span></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props:['item'],
  setup() {
    const nameAuthShow = ref(true);

    return {
      nameAuthShow,
    };
  },
  data() {
    return {
      nums: 1
    }
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    numChange() {
      this.$emit('numChange',this.nums)
    }

  },
  mounted() {
   let dd = this.item
   //debugger
  },
  created() {},
};
</script>

<style lang="less" scoped>
.container {
  z-index: 9999;
  display: flex;
  justify-content: center;
  .vpopup {
    width: 100vw;
    height: 221px;
    z-index: 9999;
    background: rgba(33, 30, 32, 1);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .price {
      margin-top: 20px;
      margin-left: 29px;
      height: 24px;
      color: rgba(255, 255, 255, 1);
      font-size: 25px;
    }
    .title{
      margin-top:8px;
      margin-left:29px;
      text-align: left;
      width: 355px;
      height: 24px;
      color: rgba(166, 166, 166, 1);
      font-size: 16px;
    }
    .order_num{
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 30px;
      margin-top:24px;
      width: 100%;
      span{
        height: 18px;
        
        color: rgba(128, 128, 128, 1);
        font-size: 12px;
      }
      .stepper{
        // margin-left:173px;

      }
    }
    .next{
      width: 326px;
      height: 35px;
      margin-top:24px;
      margin-left: 25px;
      background: linear-gradient(90deg, rgba(143, 105, 59, 1) 0%, rgba(217, 173, 115, 1) 100%);
      border-radius: 17.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        display: block;
      }
    }
  }
}
/deep/ .van-popup {
  background: rgba(33, 30, 32, 1);
}
.top {
  margin-top: 16px;
}
</style>
