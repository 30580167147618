<template>
  <auth-desc v-if="showAuthFlag" @close="showAuthFlag = flase"></auth-desc>
  <DoTaskPage :item="headData" :showPopTask="showPopTask" @close="showPopupTask" />
  <combin v-if="showCombinFlag" :headData="headData" @close="showCombinFlag = false"></combin>
  <div class="hd">
  <img src="@/assets/auth_icon.png" alt="" class="ycwf" @click="showAuthFlag = true" />
    <img
      src="https://mj-1304255817.cos.ap-guangzhou.myqcloud.com/shjc/webapp/kttp.jpg"
      alt=""
      class="hdimg"
      @click="showPopupTask(true)"
      v-show="isRaffle"
    />
    <img
      src="@/assets/ycwf.png"
      alt=""
      class="ycwf"
      @click="showCombinFlag = true"
      v-show="isOtherPlay"
    />
  </div>
</template>

<script>
import AuthDesc from "@/pages/myStore/components/auth-desc";
import DoTaskPage from "./DoTaskPage.vue";
import combin from "@/pages/myStore/components/combin";
import { ref } from "vue";
export default {
  components: {
    AuthDesc,
    DoTaskPage,
    combin,
  },
  props: {
    headData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showAuthFlag: false,
      showCombinFlag: false,
    };
  },
  computed: {
    isRaffle() {
      return this.headData.raffle == "1";
    },
    isOtherPlay() {
      return this.headData.otherPlay !== null;
    },
  },
  setup() {
    let showPopTask = ref(-1);
    return {
      showPopTask,
    };
  },
  methods: {
    showPopupTask(subStatus) {
      if (subStatus) {
        let tmp = Math.floor(Math.random() * 100);
        this.showPopTask = tmp;
      } else {
        this.showPopTask = -1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.hd {
  margin: auto;
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  .hdimg {
    border-radius: 10px;
    width: 351px;
    margin: 12px auto 0;
  }
  .ycwf {
    border-radius: 10px;
    width: 375px;
    margin: 12px 0 0;
  }
}
</style>
